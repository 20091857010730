import { createContext } from 'react';

interface LoadingContextValue {
  isLoading: boolean;
  setLoading: (value: boolean) => void;
}

export const LoadingContext = createContext<LoadingContextValue>({
  isLoading: false,
  setLoading: () => {}
});

export default LoadingContext;
