import { createContext } from 'react';
import { Product } from '../constants/Picklist';
import { CompletedScan } from '../constants/Scan';

interface PicklistContextValue {
  currentPicklist: Product[];
  setCurrentPicklist: (picklist: Product[]) => void;
  currentOrderId: number | null;
  setCurrentPickListOrderId: (orderId: number) => void;
  currentDeliveryDate: Date | null;
  setCurrentPickListDeliveryDate: (deliveryDate: any) => void;
  userId: number | null;
  setUserId: (userId: number) => void;
  sessionId: number | null;
  setSessionId: (sessionId: number) => void;
  scanCompletedProducts: Record<number | string, CompletedScan[]>;
  completeProductScan: (productId: number, assetId?: string) => void;
  skipProductScan: (productId: number, index: number) => void;
  unskipProductScan: (productId: number, index: number) => void;
  completeBagScan: (assetId: string) => void;
  clearPicklist: () => void;
  clearBagScans: () => void;
}

export const PicklistContext = createContext<PicklistContextValue>({
  currentPicklist: [],
  setCurrentPicklist: () => {},
  currentOrderId: null,
  setCurrentPickListOrderId: () => {},
  currentDeliveryDate: null,
  setCurrentPickListDeliveryDate: () => {},
  userId: null,
  setUserId: () => {},
  sessionId: null,
  setSessionId: () => {},
  scanCompletedProducts: {},
  completeProductScan: () => {},
  skipProductScan: () => {},
  unskipProductScan: () => {},
  completeBagScan: () => {},
  clearPicklist: () => {},
  clearBagScans: () => {}
});

export default PicklistContext;
