import { useState } from 'react';
import { Alert } from '../constants/Alert';

export default function useAlert(timeout = 3000) {
  const [alert, setAlert] = useState<Alert | null>(null);

  function addAlert(alert: Alert) {
    setAlert(alert);

    setTimeout(() => {
      removeAlert();
    }, timeout);
  }

  function removeAlert() {
    setAlert(null);
  }

  return { alert, addAlert, removeAlert };
}
