import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';

let qrScanner: Html5Qrcode | undefined = undefined;

export const stopQrScanner = async (qrScanner: Html5Qrcode) => {
  if (qrScanner.getState() === Html5QrcodeScannerState.SCANNING) {
    console.log('Stopping the scanner');
    try {
      await qrScanner.stop();
    } catch {
      console.warn('Cannot stop QR scanner.');
    }
  }
};

const startQrScanner = async (qrScanner: Html5Qrcode, onScan: (decodedText: string) => void) => {
  console.log('Starting the scanner');
  try {
    await qrScanner.start(
      { facingMode: 'environment' },
      {
        fps: 750,
        aspectRatio: 1
      },
      (decodedText: string, decodedResult: any) => {
        console.log('Scanned item', decodedText);
        onScan(decodedText);
        try {
          qrScanner.stop();
        } catch {
          console.error('Cannot stop QR Scanner.');
        }
      },
      (errorMessage) => {}
    );
  } catch (err) {
    console.error(err);
    console.error('Unable to start camera');
  }
};

export const getQrScanner = (onScan: (decodedText: string) => void) => {
  if (!qrScanner) {
    qrScanner = new Html5Qrcode('reader', {
      verbose: false,
      useBarCodeDetectorIfSupported: true,
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true
      }
    });
  }
  if (qrScanner.getState() != Html5QrcodeScannerState.SCANNING) {
    startQrScanner(qrScanner, onScan);
  }
  return qrScanner;
};
