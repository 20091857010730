import { createContext } from 'react';
import { RLNrc } from '../constants/RLNrc';

interface LoginContextValue {
  email: string | null;
  setEmail: (email: string) => void;
  nrcs: RLNrc[];
  nrc: RLNrc | null;
  setNrc: (nrc: RLNrc | null) => void;
  isLoggedIn: boolean;
}

export const LoginContext = createContext<LoginContextValue>({
  email: null,
  setEmail: () => {},
  nrcs: [],
  nrc: null,
  setNrc: () => {},
  isLoggedIn: false
});

export default LoginContext;
