import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import QrScan from './QrScan';

interface QrScreneProps {
  onClose?: () => void;
  onScan?: (scanResult: string) => void;
}

export default function QrScrene({ onClose = () => {}, onScan = () => {} }: QrScreneProps) {
  return (
    <>
      <QrScan onScan={onScan} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'fixed',
          bottom: '20px',
          width: '100%'
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={onClose}
          sx={{
            width: '300px',
            margin: '0 auto'
          }}
        >
          Close
        </Button>
      </Box>
    </>
  );
}
