import { createContext } from 'react';
import { Alert } from '../constants/Alert';

interface AlertContextValue {
  alert: Alert | null;
  addAlert: (alert: Alert) => void;
  removeAlert: () => void;
}

export const AlertContext = createContext<AlertContextValue>({
  alert: null,
  addAlert: () => {},
  removeAlert: () => {}
});

export default AlertContext;
