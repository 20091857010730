export default function removeHttp(scanResult: string) {
  if (scanResult.includes('http://')) {
    return scanResult.replace('http://', '');
  }

  if (scanResult.includes('https://')) {
    return scanResult.replace('https://', '');
  }

  return scanResult;
}
