import { useState, useReducer, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLazyQuery, gql } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Home from './routes/Home';
import Picklist from './routes/Picklist';
import LoginContext from './contexts/LoginContext';
import PicklistContext from './contexts/PicklistContext';
import { RLNrc } from './constants/RLNrc';
import { CompletedScan, ScanStatus } from './constants/Scan';
import Login from './routes/Login';
import LoadingContext from './contexts/LoadingContext';
import AlertContext from './contexts/AlertContext';
import { AlertTitle } from '@mui/material';
import useAlert from './hooks/useAlert';
import { omit } from 'lodash';
import getProductKey from './utilities/getProductKey';

const listRlLocations = gql`
  query listRlLocations {
    listRlLocations {
      id
      name
      vendor
    }
  }
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/picklist',
    element: <Picklist />
  }
]);

interface ScanAction {
  type: ScanStatus | 'UNSKIPPED' | 'SCANNED_BAG' | 'CLEAR_PICKLIST' | 'CLEAR_BAG_SCANS';
  product_id?: number;
  asset_id?: string;
  index?: number;
}

const findVacantProductKey = (
  scanState: Record<number | string, CompletedScan[]>,
  productId: number
) => {
  let productKey = '';
  let index = -1;
  do {
    index += 1;
    productKey = getProductKey(productId, index);
  } while (scanState[productId].some((product) => product.product_key === productKey));
  return productKey;
};

const safelyPushItem = (
  newState: Record<number | string, CompletedScan[]>,
  item: CompletedScan
) => {
  if (item.product_id === undefined || item.product_id === null) {
    const message = 'No product ID in item. Cannot add to state.';
    console.warn(message);
    return;
  }
  if (
    newState[item.product_id!].filter((product: CompletedScan) => {
      return product.product_key === item.product_key;
    }).length > 0
  ) {
    const message = 'Product item with same product key already exists in state store.';
    console.error(message);
    return;
  }
  newState[item.product_id].push(item);
  return newState;
};

function reducer(
  state: Record<number | string, CompletedScan[]>,
  action: ScanAction
): Record<number | string, CompletedScan[]> {
  const newState = { ...state };
  console.log(`Reducer action ${action} called on new state`, newState);
  switch (action.type) {
    case 'SCANNED':
      if (action.product_id) {
        if (!newState[action.product_id]) {
          newState[action.product_id] = [];
        }
        const productKey = findVacantProductKey(newState, action.product_id);
        safelyPushItem(newState, {
          product_id: action.product_id,
          asset_id: action.asset_id,
          status: 'SCANNED',
          timestamp: DateTime.now(),
          product_key: productKey
        });
      }
      return newState;
    case 'SKIPPED':
      if (action.product_id) {
        if (!newState[action.product_id]) {
          newState[action.product_id] = [];
        }
        const productKey = !!action.index
          ? getProductKey(action.product_id, action.index)
          : findVacantProductKey(newState, action.product_id);
        safelyPushItem(newState, {
          product_id: action.product_id,
          asset_id: action.asset_id,
          status: 'SKIPPED',
          timestamp: DateTime.now(),
          product_key: productKey
        });
      }
      return newState;
    case 'UNSKIPPED':
      console.log('unskipping...');
      if (action.product_id) {
        if (newState[action.product_id]) {
          console.log('length', newState[action.product_id].length);
          if (newState[action.product_id].length === 1) {
            return omit(newState, action.product_id);
          }
          const index =
            action.index !== undefined
              ? action.index
              : newState[action.product_id].findIndex((product) => product.status === 'SKIPPED');
          const productKey = getProductKey(action.product_id, index);
          console.log('Unskipping product key', productKey);

          const skipped: CompletedScan[] = newState[action.product_id].filter(
            (product: CompletedScan) =>
              product.status === 'SKIPPED' && product.product_key != productKey
          );

          const scanned: CompletedScan[] = newState[action.product_id].filter(
            (product: CompletedScan) => product.status === 'SCANNED'
          );

          newState[action.product_id] = [...scanned, ...skipped];

          console.log('');

          return newState;
        }
      }
      return newState;
    case 'SCANNED_BAG':
      if (action.asset_id) {
        if (!newState.bag) {
          newState.bag = [];
        }
        const index = newState.bag.length;
        newState.bag.push({
          product_id: null,
          status: 'SCANNED',
          asset_id: action.asset_id,
          timestamp: DateTime.now(),
          product_key: getProductKey(0, index)
        });
      }
      return newState;
    case 'CLEAR_BAG_SCANS':
      newState.bag = [];
      return newState;
    case 'CLEAR_PICKLIST':
      return {};
    default:
      throw new Error();
  }
}

export default function App() {
  const [picklist, setPicklist] = useState<any[]>([]);
  const [currentOrderId, setCurrentOrderId] = useState<number | null>(null);
  const [currentDeliveryDate, setCurrentDeliveryDate] = useState<any>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [state, dispatch] = useReducer(reducer, {});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [nrc, setNrc] = useState<RLNrc | null>(null);
  const [nrcs, setNrcs] = useState<RLNrc[]>([]);
  const { alert, addAlert, removeAlert } = useAlert();
  const [fetchRLLocations, { loading: listLocationLoading, data: listLocationData }] = useLazyQuery(
    listRlLocations,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data) {
          setNrcs(data.listRlLocations);
        }
      }
    }
  ); // TODO: useQuery isn't changing state, so resorted to using useLazyQuery

  const loggedIn = useMemo(() => !!email && !!nrc, [email, nrc]);

  function completeProductScan(productId: number, assetId?: string) {
    dispatch({ type: 'SCANNED', product_id: productId, asset_id: assetId });
  }

  function skipProductScan(productId: number, index?: number) {
    dispatch({ type: 'SKIPPED', product_id: productId, index });
  }

  function unskipProductScan(productId: number, index?: number) {
    dispatch({ type: 'UNSKIPPED', product_id: productId, index });
  }

  function completeBagScan(assetId: string) {
    dispatch({ type: 'SCANNED_BAG', asset_id: assetId });
  }

  function clearBagScans() {
    dispatch({ type: 'CLEAR_BAG_SCANS' });
  }

  useEffect(() => {
    fetchRLLocations();
  }, []);

  useEffect(() => {
    console.log('loading: ', listLocationLoading);
    setLoading(listLocationLoading);
  }, [listLocationLoading]);

  useEffect(() => {
    console.log('listLocationData: ', listLocationData);
  }, [listLocationData]);

  const clearPicklist = () => {
    setPicklist([]);
    setCurrentOrderId(null);
    setCurrentDeliveryDate(null);
    setSessionId(null);
    dispatch({ type: 'CLEAR_PICKLIST' });
  };

  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        nrcs,
        nrc,
        setNrc,
        isLoggedIn: loggedIn
      }}>
      <PicklistContext.Provider
        value={{
          currentPicklist: picklist,
          setCurrentPicklist: setPicklist,
          setCurrentPickListOrderId: setCurrentOrderId,
          currentOrderId: currentOrderId,
          setCurrentPickListDeliveryDate: setCurrentDeliveryDate,
          currentDeliveryDate: currentDeliveryDate,
          userId,
          setUserId,
          sessionId,
          setSessionId,
          scanCompletedProducts: state,
          completeProductScan,
          skipProductScan,
          unskipProductScan,
          completeBagScan,
          clearPicklist,
          clearBagScans
        }}>
        <AlertContext.Provider
          value={{
            alert,
            addAlert,
            removeAlert
          }}>
          <LoadingContext.Provider value={{ isLoading, setLoading }}>
            {!!alert && (
              <MuiAlert
                severity={alert.severity}
                action={
                  <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
                {!!alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                {alert.message}
              </MuiAlert>
            )}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <RouterProvider router={router} />
          </LoadingContext.Provider>
        </AlertContext.Provider>
      </PicklistContext.Provider>
    </LoginContext.Provider>
  );
}
