import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  }
};

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: false
  }),
  defaultOptions,
  uri: `${process.env.REACT_APP_API_URL as string}/graphql`
});

export default apolloClient;
