import { useEffect, useState } from 'react';
import { getQrScanner, stopQrScanner } from '../utilities/qrScanner';
import style from './Qrscan.module.css';

interface QrScanProps {
  onScan?: (scanResult: string) => void;
}

export default function QrScan({ onScan = (scanResult: string) => {} }: QrScanProps) {
  useEffect(() => {
    const qrScanner = getQrScanner(onScan);
    return () => {
      stopQrScanner(qrScanner);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        backdropFilter: 'blur(10px)'
      }}
    >
      {/* Div to show the scan box for qr scanning */}
      {/* calc(50vh - 45vw) 5vw == if borderWidth is set to 50vh and 50vw, the scan box will be the cent part of the screen */}
      {/* taking the half way mark of the hieght (50vh) and subtracting the 45vw (50vw - 5vw) we get the width of what 5vw would be */}
      {/* and assign it to the height to create the square shape */}
      <div
        style={{
          position: 'absolute',
          borderWidth: 'calc(50vh - 45vw) 5vw',
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.48)',
          boxSizing: 'border-box',
          inset: '0px',
          zIndex: '10000'
        }}
      >
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40px',
            height: '5px',
            top: '-5px',
            left: '0px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40px',
            height: '5px',
            top: '-5px',
            right: '0px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40px',
            height: '5px',
            bottom: '-5px',
            left: '0px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40px',
            height: '5px',
            bottom: '-5px',
            right: '0px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '5px',
            height: '45px',
            top: '-5px',
            left: '-5px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '5px',
            height: '45px',
            bottom: '-5px',
            left: '-5px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '5px',
            height: '45px',
            top: '-5px',
            right: '-5px'
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '5px',
            height: '45px',
            bottom: '-5px',
            right: '-5px'
          }}
        ></div>
      </div>
      {/* Div to show the scan box for qr scanning */}
      <div id="reader" className={style.container}></div>
    </div>
  );
}
