/**
 * Adds an event listener for keypress events to the document and
 * calls the given handler after a delay since last detected keypress
 * event.
 *
 * This returns a callable function that will remove the event listener
 * and clear the interval.
 *
 * It is expected the developer will call this function in a useEffect hook.
 * The developer should ensure that the return of this function is returned
 * in the effect hook so that the teardown is handled properly.
 *
 * @param inputHandler
 * @returns
 */
export const watchScan = (inputHandler: CallableFunction) => {
  const timerThreshold = 10; // Determines how long to wait since last keypress event before calling handler with collected input.
  let input = ''; // Keypress events append characters to this variable.
  let keypressDetected = false; // Set to true once we first detect a keypress event. Reset to false after we call the handler.
  let lastEventTime = new Date(); // The time when keypress event was last captured.

  // A continuous loop checks if a keypress event was detected
  // and waits for a lag before calling the given input handler.
  const interval = setInterval(() => {
    if (keypressDetected && new Date().getTime() - lastEventTime.getTime() > timerThreshold) {
      inputHandler(input);
      input = '';
      keypressDetected = false;
    }
  });

  const handleKeydown = (event: KeyboardEvent) => {
    input += event.key;
    keypressDetected = true;
    lastEventTime = new Date();
  };
  document.addEventListener('keypress', handleKeydown);

  return () => {
    clearInterval(interval);
    document.removeEventListener('keypress', handleKeydown);
  };
};
