import { useContext, useState, useMemo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HideSource from '@mui/icons-material/HideSource';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import PicklistContext from '../../contexts/PicklistContext';
import Typography from '@mui/material/Typography';
import { ProductStatus } from '../../constants/ProductStatus';
import { Product } from '../../constants/Picklist';

interface ProductCompleteIconProps {
  scanStatus: ProductStatus;
}

function ProductCompleteIcon({ scanStatus }: ProductCompleteIconProps) {
  if (scanStatus === 'SCANNED') {
    return <CheckCircleIcon style={{ color: 'green' }} />;
  } else if (scanStatus === 'SKIPPED') {
    return <HideSource style={{ color: 'orange' }} />;
  }
  return <HighlightOffIcon />;
}

interface ProductItemProps {
  productKey: string;
  product: Product;
  index: number;
  isLast: boolean;
}

export default function ProductItem({ product, productKey, index, isLast }: ProductItemProps) {
  const { skipProductScan, unskipProductScan, scanCompletedProducts } = useContext(PicklistContext);

  if (!product) {
    return null;
  }

  const handleSkipClicked = () => {
    if (status === 'SKIPPED') {
      // Unskipping
      unskipProductScan(product.product_id, index);
    } else {
      skipProductScan(product.product_id, index);
    }
  };

  const status = useMemo(() => {
    const foundProducts = scanCompletedProducts[product.product_id]?.filter(
      (scannedProduct) => scannedProduct.product_key === productKey
    );
    return foundProducts?.length ? foundProducts[0].status : 'INCOMPLETE';
  }, [product, scanCompletedProducts]);

  return (
    <Stack>
      <ListItem
        secondaryAction={
          status !== 'SCANNED' && (
            <Button size="small" onClick={handleSkipClicked}>
              {status === 'SKIPPED' ? 'unskip' : 'skip'}
            </Button>
          )
        }>
        <ListItemIcon>
          <ProductCompleteIcon key={productKey} scanStatus={status} />
        </ListItemIcon>
        <ListItemText
          primary={product.warehouse_product_name || product.product_name}
          secondary={
            <Stack>
              <Typography variant="body2">{product.warehouse_location}</Typography>
              <Typography variant="body2">ID: {product.product_id}</Typography>
              <Typography variant="body2">{productKey}</Typography>
            </Stack>
          }
        />
      </ListItem>
      {!isLast && <Divider variant="inset" component="li" />}
    </Stack>
  );
}
