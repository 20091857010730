import { useState, useCallback, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import Button from '@mui/material/Button';
import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import QrScrene from '../../QrScan';
import PicklistContext from '../../contexts/PicklistContext';
import { Stack } from '@mui/material';
import LoginContext from '../../contexts/LoginContext';
import removeHttp from '../../utilities/removeHttp';
import LoadingContext from '../../contexts/LoadingContext';
import AlertContext from '../../contexts/AlertContext';
import { watchScan } from '../../utilities/zebraScanner';
import { PicklistContainer } from '../../constants/Picklist';

const getPicklistByTopangaUserId = gql`
  query ($topanga_user_id_hash: String, $order_id: Int) {
    getPicklistByTopangaUserIdHash(
      topanga_user_id_hash: $topanga_user_id_hash
      order_id: $order_id
    ) {
      picklist {
        product_id
        product_name
        product_quantity
        warehouse_location
        warehouse_product_name
        is_am_packed
      }
      user_id
      order_intent_id
      order_id
      delivery_date
    }
  }
`;

const startPackSession = gql`
  mutation (
    $packer_email: String!
    $member_user_id: Int!
    $location_id: String!
    $order_intent_id: Int
    $order_id: Int
    $rl_vendor: RlVendor!
    $timestamp: TimeStamp!
  ) {
    startPackSession(
      packer_email: $packer_email
      member_user_id: $member_user_id
      location_id: $location_id
      order_intent_id: $order_intent_id
      order_id: $order_id
      rl_vendor: $rl_vendor
      timestamp: $timestamp
    ) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      order_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`;

interface GetPicklistByTopangaUserIdResponse {
  getPicklistByTopangaUserIdHash: PicklistContainer;
}

interface GetPicklistPayload {
  topanga_user_id_hash: string | undefined;
  order_id: number | undefined;
}

const TOPANGA_USER_HASH_PREFIX = 'TPC~';
const ROUNDS_1RL_URL_PREFIX = '1rl.us/refill?order_id=';

export default function Home() {
  const navigate = useNavigate();
  const { email, nrc, setEmail, setNrc } = useContext(LoginContext);
  const { setLoading } = useContext(LoadingContext);
  const { addAlert } = useContext(AlertContext);
  const [picklistScanOpen, setPicklistScanOpen] = useState(false);
  const [
    getPicklist,
    { loading: getPicklistLoading, error: getPicklistError, data: getPicklistData }
  ] = useLazyQuery<GetPicklistByTopangaUserIdResponse>(getPicklistByTopangaUserId);
  const [
    startSession,
    { loading: startSessionLoading, error: startSessionError, data: startSessionData }
  ] = useMutation(startPackSession);
  const {
    setCurrentPicklist,
    setUserId,
    setSessionId,
    clearPicklist,
    setCurrentPickListOrderId,
    setCurrentPickListDeliveryDate
  } = useContext(PicklistContext);
  const { isLoggedIn } = useContext(LoginContext);

  const openPicklistScan = () => setPicklistScanOpen(true);
  const closePicklistScan = () => setPicklistScanOpen(false);

  useEffect(() => {
    if (!isLoggedIn) {
      console.log('User is missing login state. Logging out.', email, nrc);
      logout();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (getPicklistError) {
      const title = 'Something went wrong while getting picklist';
      const message = getPicklistError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }

    if (startSessionError) {
      const title = 'Something went wrong trying to start a packing session';
      const message = startSessionError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }
  }, [getPicklistError, startSessionError]);

  useEffect(() => {
    if (getPicklistData?.getPicklistByTopangaUserIdHash) {
      const {
        user_id: userId,
        order_intent_id: orderIntentId,
        order_id: orderId,
        delivery_date: deliveryDate,
        picklist
      } = getPicklistData.getPicklistByTopangaUserIdHash;
      console.log(userId, picklist, orderIntentId);
      if (userId && picklist) {
        addAlert({
          severity: 'success',
          message: `Picklist retrieve for user ID ${userId}`
        });
        setUserId(userId);
        setCurrentPicklist(picklist);
        if (orderId) setCurrentPickListOrderId(orderId);
        if (deliveryDate) setCurrentPickListDeliveryDate(deliveryDate);
        void startSession({
          variables: {
            packer_email: email,
            member_user_id: userId,
            location_id: nrc?.id,
            rl_vendor: nrc?.vendor,
            order_intent_id: orderIntentId || null,
            order_id: orderId || null,
            timestamp: DateTime.now()
          }
        });
      } else {
        const message = 'Something went wrong trying to get picklist';
        console.error(message);
        addAlert({
          severity: 'error',
          message
        });
      }
    }
  }, [getPicklistData]);

  useEffect(() => {
    console.log('startSessionData', startSessionData);
    if (startSessionData?.startPackSession) {
      setSessionId(startSessionData.startPackSession.id);
      navigate('/picklist');
    }
  }, [startSessionData]);

  useEffect(() => {
    return watchScan(handleScanPicklist);
  }, []);

  const handleScanPicklist = useCallback((scanResult: string) => {
    const getPicklistPayload: GetPicklistPayload = {
      topanga_user_id_hash: undefined,
      order_id: undefined
    };

    scanResult = removeHttp(scanResult);

    if (scanResult.startsWith(TOPANGA_USER_HASH_PREFIX)) {
      getPicklistPayload.topanga_user_id_hash = scanResult;
    }

    if (scanResult.startsWith(ROUNDS_1RL_URL_PREFIX)) {
      const prefixLength = ROUNDS_1RL_URL_PREFIX.length;
      const withoutPrefix = scanResult.slice(prefixLength);
      getPicklistPayload.order_id = +withoutPrefix;
    }

    clearPicklist();
    if (scanResult) {
      void getPicklist({ variables: getPicklistPayload });
    }
    closePicklistScan();
  }, []);

  const logout = () => {
    setEmail('');
    setNrc(null);
    navigate('/');
  };

  useEffect(() => {
    setLoading(getPicklistLoading || startSessionLoading);
  }, [getPicklistLoading, startSessionLoading]);

  return (
    <>
      <Stack p={2} spacing={2}>
        {!picklistScanOpen && (
          <Button variant="contained" onClick={openPicklistScan}>
            Scan Picklist
          </Button>
        )}
        <Button variant="outlined" color="error" onClick={logout}>
          Log Out
        </Button>
      </Stack>
      {picklistScanOpen && (
        <QrScrene onClose={() => setPicklistScanOpen(false)} onScan={handleScanPicklist} />
      )}
    </>
  );
}
