import { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import LoginContext from '../contexts/LoginContext';
import { RLNrc } from '../constants/RLNrc';
import { GetPickPackUserResponse } from '../constants/User';
import { useNavigate } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/client';
import AlertDialog from '../components/AlertDialog';

const getPickPackUserGql = gql`
  query getPickPackUser($email: String!) {
    getPickPackUser(email: $email) {
      email
    }
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const { setEmail, email, setNrc, nrc, nrcs, isLoggedIn } = useContext(LoginContext);
  const [currentEmail, setCurrentEmail] = useState<string | null>(email);
  const [currentNrc, setCurrentNrc] = useState<RLNrc | null>(nrc);
  const [userNotFoundModalOpen, setUserNotFoundModalOpen] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const [getPickPackUserQuery, { loading: getPickPackUserLoading, error: getPickPackUserError }] =
    useLazyQuery<GetPickPackUserResponse>(getPickPackUserGql, {
      variables: { email: currentEmail },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    });

  const handleLogin = async () => {
    setLoginLoading(true);
    const { data: userQueryResponse } = await getPickPackUserQuery();
    console.log(userQueryResponse);
    if (
      userQueryResponse &&
      userQueryResponse?.getPickPackUser !== undefined &&
      userQueryResponse?.getPickPackUser !== null
    ) {
      if (!!currentEmail && !!currentNrc) {
        console.log('setting and navigating', currentEmail, currentNrc);
        setEmail(currentEmail);
        setNrc(currentNrc);
      }
    } else {
      setUserNotFoundModalOpen(true);
    }
    setLoginLoading(false);
  };

  /**
   * This effect will navigate the user to the home screen if the LoginContext
   * has an email and nrc set.
   */
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn]);

  return (
    <Stack spacing={2} p={2}>
      <TextField
        onChange={(event) => {
          setCurrentEmail(event.target.value);
        }}
        inputProps={{
          type: 'email'
        }}
        required
        id="outlined-required"
        label="Email"
        placeholder="hello@therounds.co"
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          required
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentNrc || ''}
          label="location"
          onChange={(event: any) => {
            setCurrentNrc(event.target.value);
          }}
        >
          {nrcs.map((nrc) => (
            // @ts-expect-error Sets value and key for onChange event handling
            <MenuItem key={nrc.name} value={nrc}>
              {nrc.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          disabled={!currentEmail || !currentNrc || loginLoading}
          onClick={handleLogin}
        >
          Log in
        </Button>
      </FormControl>

      <div className="bottom-content">
        Version: {process.env.REACT_APP_GITHUB_SHA?.substring(0, 7)}
      </div>
      <AlertDialog
        title="User email not recognized."
        content={`The provided email address ${currentEmail} was not recognized.`}
        open={userNotFoundModalOpen}
        setOpen={setUserNotFoundModalOpen}
      />
    </Stack>
  );
}
